import { useCallback, useEffect, useState } from "react";
import { ERRORS } from "../helpers/errors";
import { log } from "../helpers";

const useParamsForIframePaywall = () => {
  const [params, setParams] = useState({});
  const [isRendered, setIsRendered] = useState(false);

  const updateParams = useCallback((args) => {
    setParams((prevState) => ({ ...prevState, ...args }));
  }, []);

  useEffect(() => {
    try {
      const urlParams = new URL(window.location.href).searchParams;
      const { url, software } = Object.fromEntries(urlParams);
      software && updateParams({ software });
      url && updateParams({ url });
    } catch (error) {
      log(error, ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS, true);
    } finally {
      setIsRendered(true);
    }
  }, [updateParams]);

  const getParams = new URLSearchParams(params).toString();
  return { getParams, isRendered };
};

export default useParamsForIframePaywall;
